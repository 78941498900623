class BackgroundScroll {

    constructor(rootElement) {
        if (!rootElement) {
            return;
        }
        this.rootElement = rootElement;

        this.addListeners();
    }

    addListeners() {
        window.addEventListener('scroll', e => {
            const doc = document.documentElement,
                scrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0),
                pageHeight = Math.max(doc.clientHeight, window.innerHeight || 0),
                percentage = parseInt(100 * (scrollTop/pageHeight));

            this.rootElement.style.backgroundPosition = percentage + '% ' + percentage + '%';

        });
    };

}

export default BackgroundScroll;