class Header {

    constructor() {
        this.rootElement = document.querySelector('header');
        if (!this.rootElement) return;

        this.addListeners();
    }

    addListeners() {
        window.addEventListener('scroll', e => {
            if (e.target.scrollingElement.scrollTop === 0) {
                this.rootElement.classList.remove('scrolled')
            } else {
                this.rootElement.classList.add('scrolled')
            }
        });
    };


}

export default Header;