class BatteryDetailPage {

    constructor() {
        this.rootElement = document.querySelector('.single-battery');
        if (!this.rootElement) return;

        this.downLinkElement = this.rootElement.querySelector('.link.down');
        this.specsElement = this.rootElement.querySelector('.mid');
        this.headerElement = document.querySelector('header');

        this.addListeners();
    }

    addListeners() {
        this.downLinkElement.addEventListener('click', e => {
            window.scroll({top: this.specsElement.getBoundingClientRect().top + window.scrollY - this.headerElement.offsetHeight, left: 0, behavior: 'smooth' });

        });
    };


}

export default BatteryDetailPage;