class ContactForm {

    constructor() {
        this.rootElement = document.querySelector('.wpcf7-form');
        if (!this.rootElement) {
            return;
        }

        this.rootElement.querySelectorAll('input, textarea').forEach(input => {
            input.setAttribute("required", true);
        });
    }

}

export default ContactForm;