class Homepage {

    constructor() {
        this.rootElement = document.querySelector('.homepage');
        if (!this.rootElement) {
            return;
        }

        this.mainElement = document.querySelector('main');
        this.mainElement.classList.add('no-scroll');
        setTimeout(() => {
            this.mainElement.classList.remove('no-scroll');
        }, 2500);

        this.headerElement = this.rootElement.querySelector('.homepage-header');
        this.showcaseElement = this.rootElement.querySelector('.homepage-showcase');
        if (this.showcaseElement && this.headerElement) {
            this.addShowcaseListener();
        }
    }

    addShowcaseListener() {
        window.addEventListener('scroll', this.updateShowcaseCSS.bind(this));
        window.addEventListener('resize', this.updateShowcaseCSS.bind(this));
    }

    updateShowcaseCSS() {
        const doc = document.documentElement,
            scrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0),
            headerHeight = this.headerElement.offsetHeight,
            scaleRatio = 1 - scrollTop / headerHeight / 5,
            translateRatioY = -20 + scrollTop / (headerHeight * 2) * 180 - Math.pow(scrollTop / (headerHeight * 2), 2) * 150,
            translateRatioX = 35 + scrollTop / headerHeight * 3;

        this.showcaseElement.style.opacity = 1;
        this.showcaseElement.style.animation = 'none';
        this.showcaseElement.style.transform = 'translateY(' + translateRatioY + '%) translateX(' + translateRatioX + '%) scale(' + scaleRatio + ')';
    }
}

export default Homepage;