import './polyfills.js';
import Header from '../shared/header';
import ContactForm from "../shared/contact-form";
import BackgroundScroll from "../shared/background-scroll";
import Homepage from "../templates/home/index";
import BatteryDetailPage from "../shared/single-battery";


new Header();
new Homepage();
new ContactForm();
new BatteryDetailPage();

document.querySelectorAll('.content-panel.background').forEach(element => {
    new BackgroundScroll(element);
});
